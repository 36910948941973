import Tiles from "@/components/shared/tiles/tiles"
import lady from "@/images/user.png"
import home from "@/images/home-green.png"
import React from "react"
import { injectIntl } from "gatsby-plugin-intl"

const CompanyTiles = ({intl}) => {

  const tilesContent = [
    {
      title: intl.formatMessage({ id: "about.info.client" }),
      content: (
        <p>
          {intl.formatMessage({ id: "about.info.client.content.front" })}
          <span>
            {" "}
            {intl.formatMessage({ id: "about.info.client.content.bold" })}{" "}
          </span>
          {intl.formatMessage({ id: "about.info.client.content.back" })}
        </p>
      ),
      icon: lady,
    },
    {
      title: intl.formatMessage({ id: "about.info.experience" }),
      content: (
        <p>
          {intl.formatMessage({ id: "about.info.experience.content.front" })}
          <span>
            {" "}
            {intl.formatMessage({ id: "about.info.experience.content.bold" })}
          </span>
        </p>
      ),
      icon: home,
    },
    {
      title: intl.formatMessage({ id: "about.info.assets" }),
      content: (
        <p>
          {intl.formatMessage({ id: "about.info.assets.content.front" })}
          <span>
            {" "}
            {intl.formatMessage({ id: "about.info.assets.content.bold" })}{" "}
          </span>
          {intl.formatMessage({ id: "about.info.assets.content.back" })}
        </p>
      ),
      icon: lady,
    },
  ]
  return <Tiles tilesContent={tilesContent} />
}

export default injectIntl(CompanyTiles)
