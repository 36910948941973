import React from "react"
import Layout from "@/components/layout"
import { Col, Container, Row } from "react-bootstrap"

import Helmet from "react-helmet"
import styled from "styled-components"
import CompanyTiles from "@/components/company/companyTiles"

import headingBg from "@/images/z-11/z-11-general-contracting-bg.jpg"
import logoImage from "@/images/z-11/z-11-logo-o.png"
import obrazImage from "@/images/z-11/z-11-obraz.png"
import ContactForm from "@/components/shared/contactForm/contactForm"
import EstateSlider from "@/components/estates/estateSlider"
import img_ojp from "@/images/z-12/ojp.jpg"
import img_ogw from "@/images/z-12/ogw.jpg"
import img_ojp2 from "@/images/z-12/ojp2.jpg"
import img_ogw2 from "@/images/z-12/ogw2.jpg"

const GeneralContractingPage = () => {
  const slides = [
    { title: "Osiedle Gdynia Wiczlino II", img: img_ogw2 },
    { title: "Osiedle Janowo Park II", img: img_ojp2 },
    { title: "Osiedle Janowo Park", img: img_ojp },
    { title: "Osiedle Gdynia Wiczlino", img: img_ogw },
  ]

  return (
    <>
      <Helmet>
        <title>WILLBUD | Generalne wykonawstwo</title>
      </Helmet>
      <Layout>
        <GeneralContractingPageWrapper>
          <div className="heading">
            <div className="body">
              <Row>
                <Col md={5}>
                  <div className="logo">
                    <img
                      src={logoImage}
                      alt="Willbud - Budownictwo"
                      width={435}
                    />
                  </div>
                </Col>
                <Col>
                  <p>
                    <strong>
                      Korzystając z wiedzy i&nbsp;wieloletniego doświadczenia
                      naszych specjalistów realizujemy inwestycje budowlane od
                      projektu aż po prace wykończeniowe.
                    </strong>
                  </p>
                  <p>
                    Każda nasza inwestycja jest dopracowana w&nbsp;najmniejszym
                    szczególe, a&nbsp;wszystkie etapy prac przebiegają
                    bezpiecznie i&nbsp;z&nbsp;pełnym zaangażowaniem.
                  </p>
                  <p>
                    Najważniejsza jest dla nas jakość, dlatego wszystkie nasze
                    inwestycje powstają w&nbsp;oparciu o&nbsp;nowoczesne
                    i&nbsp;sprawdzone technologie budowlane.
                  </p>
                </Col>
              </Row>
            </div>
          </div>

          <div className="content">
            <Container>
              <Row>
                <Col lg={6} md={12}>
                  <p>
                    <strong>
                      Specjalizujemy się w&nbsp;kompleksowej realizacji wysokiej
                      jakości obiektów mieszkaniowych, a&nbsp;także obiektów
                      biurowych, użyteczności publicznej, specjalistycznych hal
                      przemysłowo-magazynowych oraz obiektów komercyjnych.
                    </strong>
                  </p>

                  <p>Zajmujemy się generalnym wykonawstwem inwestycji:</p>
                  <ul>
                    <li>Budownictwa wielorodzinnego oraz jednorodzinnego</li>
                    <li>Kompleksów biurowych</li>
                    <li>Budynków i hal przemysłowych</li>
                    <li>Kompleksów basenowych</li>
                    <li>Obiektów hotelarskich</li>
                    <li>Hal widowiskowo-sportowych</li>
                  </ul>
                </Col>
                <Col lg={6} md={12}>
                  <div className="image">
                    <img src={obrazImage} alt="..." width={796} />
                  </div>
                </Col>
              </Row>
            </Container>

            <div className="slider-wrapper">
              <Container>
                <Row>
                  <Col>
                    <EstateSlider slides={slides} />
                  </Col>
                </Row>
              </Container>
            </div>

            <Container>
              <Row>
                <Col className="footer">
                  <p>
                    Chcesz dowiedzieć się więcej na temat naszej oferty?
                    A&nbsp;może jesteś podwykonawcą, który chciałby z&nbsp;nami
                    współpracować? Skontaktuj się z&nbsp;nami za pomocą
                    poniższego formularza kontaktowego.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="form-wrapper">
            <Container>
              <Row>
                <Col className="form">
                  <ContactForm gwView={true} />
                </Col>
              </Row>
            </Container>
          </div>

          <CompanyTiles />
        </GeneralContractingPageWrapper>
      </Layout>
    </>
  )
}

export default GeneralContractingPage

export const GeneralContractingPageWrapper = styled.div`
  font-size: 18px;

  strong {
    font-weight: 500;
  }

  .heading {
    background: url(${headingBg}) top center no-repeat;
    background-size: cover;
    margin-top: -160px;
    padding: 380px 0 100px;

    .body {
      background: rgba(255, 255, 255, 0.75);
      border-radius: 14px;
      padding: 35px 30px 35px 0;
      max-width: 1200px;
      margin: 0 auto;

      @media (max-width: 1250px) {
        margin: 0 25px;
      }
    }

    .logo {
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
      }
    }
  }

  .content {
    padding: 70px 0 0;

    .image {
      margin-right: -50px;
      img {
        max-width: 100%;
      }
    }
  }
  .footer {
    text-align: center;
    padding: 30px 30px 15px;
    max-width: 800px;
    margin: 0 auto;
  }

  .form-wrapper {
    background-color: #f8f8f8;
    padding: 40px 0;
  }
  .form {
    max-width: 740px;
    margin: 0 auto;
  }

  .slider-wrapper {
    background-color: #f8f8f8;
    margin-top: 30px;
    padding: 40px 0;
  }
`
