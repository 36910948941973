import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import {TilesStyled} from './tilesStyle'
const Tiles = ({tilesContent}) => (
  <TilesStyled>
    <Container>
      <Row>
        {tilesContent.map((item, i) =>
        <Col key={i} xs={12} lg={4} className="element-wrapper">
          <div className={"single-element"}>
            <div className="icon">
              <img src={item.icon} alt="" />
            </div>
            <div className={"title"}>{item.title}</div>
              {item.content}
          </div>
        </Col>
        )}
      </Row>
    </Container>
  </TilesStyled>
)

export default Tiles
