import styled from "styled-components"

export const TilesStyled = styled.div`
  background-color: #f8f8f8;
  font-size: 20px;
  overflow: hidden;
  .element-wrapper,
  .single-element {
    text-align: center;
  }
  .element-wrapper {
    position: relative;
    padding: 80px 0!important;
  }
  .element-wrapper:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -100vw;
    height: 1px;
    //width: calc(100% + 45px);
    width: 300vw;
    background-color: #000;
    opacity: 0.06;
    @media (min-width: 1200px) {
      display: none;
    }
  }
  .element-wrapper:last-child:after {
    display: none;
  }
  .single-element {
    height: 100%;
    .title {
      text-transform: uppercase;
      font-weight: 700;
      margin: 39px 0 20px 0;
    }
    p {
      font-weight: 300;
      padding: 0;
      margin: 0;
    }
    p:nth-last-of-type(1) {
      //padding-bottom: 30px;
    }
    p > span {
      font-weight: 500;
    }
    .icon {
      margin: 0 0 39px 0;
      img {
        height: 80px;
      }
    }
  }
  @media (min-width: 1200px) {
    .element-wrapper:nth-of-type(even) .single-element {
      padding-left: 45px;
      padding-right: 45px;
    }
    .element-wrapper:nth-of-type(1) .single-element {
      padding-right: 90px;
    }
    .element-wrapper:nth-of-type(3) .single-element {
      padding-left: 90px;
    }
  }
`
